import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import Form from '../components/Form';
import TextInput from '../components/TextInput';
import Button from '../components/Button';

const CookieTablePage = () => {
  const [name, setName] = useState('');
  const [cookie, setCookie] = useState('');
  const [amount, setAmount] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [hasSubmitted, setSubmitted] = useState(false);

  const variants = {
    hidden: {
      opacity: 0,
      transitionEnd: {
        display: 'none',
      },
    },
    visible: {
      display: 'block',
      opacity: 1,
    },
  };

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&');
  };

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'cookieTable', Name: name, Cookie: cookie, Amount: amount }),
    })
      .then(() => {
        setLoading(false);
        setSubmitted(true);
      })
      .catch(error => {
        alert(error.message);
        setLoading(false);
      });
  }

  return (
    <>
      <h1 className='heading'>Cookie Table</h1>
      <section>
        <p>
          The cookie table is a Pittsburgh tradition that we’re excited to make a part of our
          celebration. Traditionally, friends and family prepare cookies to be displayed on the
          cookie table, and eventually enjoyed by all guests throughout the night - even taking a
          box home with you! Some say the history of the cookie table is rooted in immigrants during
          the Great Depression as a way to keep costs low. While we’ll still have a wedding cake, we
          can’t have a Pittsburgh wedding without the classic cookie table.
        </p>
        <p>
          We would love for you to contribute a batch of cookies to the cookie table. Please fill
          out the form below so we can have an idea of how many cookies to expect, but no worries if
          you don’t fill out the form and still bring some to share!
        </p>
        <FormWrapper>
          <motion.div animate={hasSubmitted ? 'hidden' : 'visible'} variants={variants}>
            <Form onSubmit={handleSubmit} netlify={true} name='cookieTable'>
              <input type='hidden' name='form-name' value='contact' />
              <TextInput name='Name' state={name} setState={setName} />
              <TextInput name='Cookie' label='Type of Cookie' state={cookie} setState={setCookie} />
              <TextInput
                name='Amount'
                label='How many dozen?'
                state={amount}
                setState={setAmount}
              />
              <Button isLoading={isLoading}>{isLoading ? 'Submitting...' : 'Submit'}</Button>
            </Form>
          </motion.div>
          <SubmittedMessage
            hasSubmitted={hasSubmitted}
            animate={hasSubmitted ? 'visible' : 'hidden'}
            variants={variants}
            transition={{ delay: '0.5' }}
          >
            <h2>We can't wait to eat your cookies.</h2>
            <p>
              The venue will tray and display the cookies, so all you have to do is bake and bring
              them!
            </p>
          </SubmittedMessage>
        </FormWrapper>
      </section>
    </>
  );
};

const FormWrapper = styled.div`
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SubmittedMessage = styled(motion.div)`
  text-align: center;
`;

export default CookieTablePage;
